import { createApp } from "vue";
import { createPinia } from "pinia";
// import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import { VueQueryPlugin } from "vue-query";
import VueKonva from "vue-konva";
import * as Sentry from "@sentry/vue";
import { registerSW } from "virtual:pwa-register";

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "@/assets/primeflex.css";
import "./assets/main.scss";

// Theme
// import "primevue/resources/themes/saga-blue/theme.css";
// import "primevue/resources/themes/lara-light-blue/theme.css";
// import "primevue/resources/themes/vela-blue/theme.css";
import "primevue/resources/themes/soho-dark/theme.css";

// TODO: Switch to prompt type update mode for PWA
registerSW({ immediate: true });

const app = createApp(App);
const pinia = createPinia();
Sentry.init({
  app,
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
});
// const head = createHead({
//   link: [
//     {
//       rel: "stylesheet",
//       href: "https://unpkg.com/primevue/resources/themes/soho-dark/theme.css",
//       id: "theme-file",
//     },
//   ],
// });

app.use(pinia);
// app.use(head);
app.use(router);
app.use(PrimeVue);
app.use(VueQueryPlugin);
app.use(VueKonva);

app.mount("#app");
