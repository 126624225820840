/* tslint:disable */
/* eslint-disable */
/**
 * Estimation Station
 * Backend API powering the estimation station platform.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: chase@rutkowski.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lineOne': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'lineTwo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'province': string;
}
/**
 * 
 * @export
 * @interface AssignUsers
 */
export interface AssignUsers {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignUsers
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BaseCommon
 */
export interface BaseCommon {
    /**
     * 
     * @type {string}
     * @memberof BaseCommon
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseCommon
     */
    'deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseCommon
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof BaseCommon
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {ShortUser}
     * @memberof Comment
     */
    'author': ShortUser;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'comment': string;
    /**
     * 
     * @type {BaseCommon}
     * @memberof Comment
     */
    'common': BaseCommon;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'reference': string;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface CreateEstimate
 */
export interface CreateEstimate {
    /**
     * 
     * @type {Address}
     * @memberof CreateEstimate
     */
    'address': Address;
    /**
     * 
     * @type {Customer}
     * @memberof CreateEstimate
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEstimate
     */
    'estimatorIDs': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEstimate
     */
    'installerIDs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateEstimate
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEstimate
     */
    'statusComment': string;
}
/**
 * 
 * @export
 * @interface CreateEstimateComment
 */
export interface CreateEstimateComment {
    /**
     * 
     * @type {string}
     * @memberof CreateEstimateComment
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'authID'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'avatar'?: string;
    /**
     * 
     * @type {UserContact}
     * @memberof CreateUser
     */
    'contact': UserContact;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'comment': string;
    /**
     * 
     * @type {CustomerContact}
     * @memberof Customer
     */
    'contact': CustomerContact;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface CustomerContact
 */
export interface CustomerContact {
    /**
     * 
     * @type {string}
     * @memberof CustomerContact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContact
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContact
     */
    'prefferedMethod': string;
}
/**
 * 
 * @export
 * @interface Estimate
 */
export interface Estimate {
    /**
     * 
     * @type {Address}
     * @memberof Estimate
     */
    'address': Address;
    /**
     * 
     * @type {BaseCommon}
     * @memberof Estimate
     */
    'common': BaseCommon;
    /**
     * 
     * @type {Customer}
     * @memberof Estimate
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<ShortUser>}
     * @memberof Estimate
     */
    'estimators': Array<ShortUser>;
    /**
     * 
     * @type {Inspection}
     * @memberof Estimate
     */
    'inspection'?: Inspection;
    /**
     * 
     * @type {Array<ShortUser>}
     * @memberof Estimate
     */
    'installers': Array<ShortUser>;
    /**
     * 
     * @type {string}
     * @memberof Estimate
     */
    'jobType': string;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Estimate
     */
    'pictures': Array<Image>;
    /**
     * 
     * @type {string}
     * @memberof Estimate
     */
    'status': string;
    /**
     * 
     * @type {Array<Status>}
     * @memberof Estimate
     */
    'statusHistory': Array<Status>;
    /**
     * 
     * @type {string}
     * @memberof Estimate
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface GetBlob200Response
 */
export interface GetBlob200Response {
    /**
     * 
     * @type {string}
     * @memberof GetBlob200Response
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface GetHealthcheck200Response
 */
export interface GetHealthcheck200Response {
    /**
     * 
     * @type {string}
     * @memberof GetHealthcheck200Response
     */
    'environment'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealthcheck200Response
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'creator': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    'thumbnailPath': string;
}
/**
 * 
 * @export
 * @interface Inspection
 */
export interface Inspection {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Inspection
     */
    'details'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Inspection
     */
    'materials'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'currentPage': number;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'firstPage': number;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'lastPage': number;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    'totalRecords': number;
}
/**
 * 
 * @export
 * @interface PostPhoto201Response
 */
export interface PostPhoto201Response {
    /**
     * 
     * @type {string}
     * @memberof PostPhoto201Response
     */
    'photo': string;
    /**
     * 
     * @type {string}
     * @memberof PostPhoto201Response
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface QueryEstimateComments200Response
 */
export interface QueryEstimateComments200Response {
    /**
     * 
     * @type {Metadata}
     * @memberof QueryEstimateComments200Response
     */
    'meta': Metadata;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof QueryEstimateComments200Response
     */
    'results': Array<Comment>;
}
/**
 * 
 * @export
 * @interface QueryEstimates200Response
 */
export interface QueryEstimates200Response {
    /**
     * 
     * @type {Metadata}
     * @memberof QueryEstimates200Response
     */
    'meta': Metadata;
    /**
     * 
     * @type {Array<ShortEstimate>}
     * @memberof QueryEstimates200Response
     */
    'results': Array<ShortEstimate>;
}
/**
 * 
 * @export
 * @interface QueryUsers200Response
 */
export interface QueryUsers200Response {
    /**
     * 
     * @type {Metadata}
     * @memberof QueryUsers200Response
     */
    'meta': Metadata;
    /**
     * 
     * @type {Array<User>}
     * @memberof QueryUsers200Response
     */
    'results': Array<User>;
}
/**
 * 
 * @export
 * @interface ShortEstimate
 */
export interface ShortEstimate {
    /**
     * 
     * @type {Address}
     * @memberof ShortEstimate
     */
    'address': Address;
    /**
     * 
     * @type {BaseCommon}
     * @memberof ShortEstimate
     */
    'common': BaseCommon;
    /**
     * 
     * @type {Customer}
     * @memberof ShortEstimate
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<ShortUser>}
     * @memberof ShortEstimate
     */
    'estimators': Array<ShortUser>;
    /**
     * 
     * @type {Array<ShortUser>}
     * @memberof ShortEstimate
     */
    'installers': Array<ShortUser>;
    /**
     * 
     * @type {string}
     * @memberof ShortEstimate
     */
    'jobType': string;
    /**
     * 
     * @type {string}
     * @memberof ShortEstimate
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ShortEstimate
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface ShortUser
 */
export interface ShortUser {
    /**
     * 
     * @type {string}
     * @memberof ShortUser
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUser
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUser
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof ShortUser
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateEstimate
 */
export interface UpdateEstimate {
    /**
     * 
     * @type {Address}
     * @memberof UpdateEstimate
     */
    'address': Address;
    /**
     * 
     * @type {Customer}
     * @memberof UpdateEstimate
     */
    'customer': Customer;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEstimate
     */
    'estimatorIDs': Array<string>;
    /**
     * 
     * @type {Inspection}
     * @memberof UpdateEstimate
     */
    'inspection'?: Inspection;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEstimate
     */
    'installerIDs': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateEstimate
     */
    'jobType': string;
    /**
     * 
     * @type {Array<Image>}
     * @memberof UpdateEstimate
     */
    'pictures': Array<Image>;
    /**
     * 
     * @type {string}
     * @memberof UpdateEstimate
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEstimate
     */
    'statusComment': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEstimate
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'avatar'?: string;
    /**
     * 
     * @type {UserContact}
     * @memberof UpdateUser
     */
    'contact': UserContact;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'authID': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar'?: string;
    /**
     * 
     * @type {BaseCommon}
     * @memberof User
     */
    'common': BaseCommon;
    /**
     * 
     * @type {Contact}
     * @memberof User
     */
    'contact': Contact;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface UserContact
 */
export interface UserContact {
    /**
     * 
     * @type {string}
     * @memberof UserContact
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserContact
     */
    'phone'?: string;
}

/**
 * BlobsApi - axios parameter creator
 * @export
 */
export const BlobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a signed blob URL for direct access to a private blob file
         * @param {string} [blobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlob: async (blobId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/blobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (blobId !== undefined) {
                localVarQueryParameter['blobId'] = blobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a photo to blob store
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPhoto: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/blobs/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlobsApi - functional programming interface
 * @export
 */
export const BlobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlobsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a signed blob URL for direct access to a private blob file
         * @param {string} [blobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlob(blobId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBlob200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlob(blobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a photo to blob store
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPhoto(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostPhoto201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPhoto(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlobsApi - factory interface
 * @export
 */
export const BlobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlobsApiFp(configuration)
    return {
        /**
         * Get a signed blob URL for direct access to a private blob file
         * @param {BlobsApiGetBlobRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlob(requestParameters: BlobsApiGetBlobRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetBlob200Response> {
            return localVarFp.getBlob(requestParameters.blobId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a photo to blob store
         * @param {BlobsApiPostPhotoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPhoto(requestParameters: BlobsApiPostPhotoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PostPhoto201Response> {
            return localVarFp.postPhoto(requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBlob operation in BlobsApi.
 * @export
 * @interface BlobsApiGetBlobRequest
 */
export interface BlobsApiGetBlobRequest {
    /**
     * 
     * @type {string}
     * @memberof BlobsApiGetBlob
     */
    readonly blobId?: string
}

/**
 * Request parameters for postPhoto operation in BlobsApi.
 * @export
 * @interface BlobsApiPostPhotoRequest
 */
export interface BlobsApiPostPhotoRequest {
    /**
     * 
     * @type {File}
     * @memberof BlobsApiPostPhoto
     */
    readonly file?: File
}

/**
 * BlobsApi - object-oriented interface
 * @export
 * @class BlobsApi
 * @extends {BaseAPI}
 */
export class BlobsApi extends BaseAPI {
    /**
     * Get a signed blob URL for direct access to a private blob file
     * @param {BlobsApiGetBlobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlobsApi
     */
    public getBlob(requestParameters: BlobsApiGetBlobRequest = {}, options?: AxiosRequestConfig) {
        return BlobsApiFp(this.configuration).getBlob(requestParameters.blobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a photo to blob store
     * @param {BlobsApiPostPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlobsApi
     */
    public postPhoto(requestParameters: BlobsApiPostPhotoRequest = {}, options?: AxiosRequestConfig) {
        return BlobsApiFp(this.configuration).postPhoto(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EstimatesApi - axios parameter creator
 * @export
 */
export const EstimatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign an estimator to an estimate.
         * @param {string} estimateID 
         * @param {AssignUsers} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEstimator: async (estimateID: string, body?: AssignUsers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('assignEstimator', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}/estimator`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign an installer to an estimate.
         * @param {string} estimateID 
         * @param {AssignUsers} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignInstaller: async (estimateID: string, body?: AssignUsers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('assignInstaller', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}/installer`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create estimate record.
         * @param {CreateEstimate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimate: async (body?: CreateEstimate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/estimates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a comment to an estimate
         * @param {string} estimateID 
         * @param {CreateEstimateComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimateComment: async (estimateID: string, body?: CreateEstimateComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('createEstimateComment', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}/comments`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an estimate.
         * @param {string} estimateID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstimate: async (estimateID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('deleteEstimate', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get estimate record.
         * @param {string} estimateID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimate: async (estimateID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('getEstimate', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query estimate comments.
         * @param {string} estimateID 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEstimateComments: async (estimateID: string, page?: number, pageSize?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('queryEstimateComments', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}/comments`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query estimate records.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [city] 
         * @param {string} [province] 
         * @param {string} [country] 
         * @param {string} [postalCode] 
         * @param {string} [status] 
         * @param {string} [jobType] 
         * @param {string} [customer] 
         * @param {string} [estimator] 
         * @param {string} [installer] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEstimates: async (page?: number, pageSize?: number, sort?: string, city?: string, province?: string, country?: string, postalCode?: string, status?: string, jobType?: string, customer?: string, estimator?: string, installer?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/estimates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (province !== undefined) {
                localVarQueryParameter['province'] = province;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }

            if (customer !== undefined) {
                localVarQueryParameter['customer'] = customer;
            }

            if (estimator !== undefined) {
                localVarQueryParameter['estimator'] = estimator;
            }

            if (installer !== undefined) {
                localVarQueryParameter['installer'] = installer;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign an estimator from an estimate.
         * @param {string} estimateID 
         * @param {string} estimatorID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEstimator: async (estimateID: string, estimatorID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('unassignEstimator', 'estimateID', estimateID)
            // verify required parameter 'estimatorID' is not null or undefined
            assertParamExists('unassignEstimator', 'estimatorID', estimatorID)
            const localVarPath = `/api/estimates/{estimateID}/estimator/{estimatorID}`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)))
                .replace(`{${"estimatorID"}}`, encodeURIComponent(String(estimatorID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign an installer from an estimate.
         * @param {string} estimateID 
         * @param {string} installerID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignInstaller: async (estimateID: string, installerID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('unassignInstaller', 'estimateID', estimateID)
            // verify required parameter 'installerID' is not null or undefined
            assertParamExists('unassignInstaller', 'installerID', installerID)
            const localVarPath = `/api/estimates/{estimateID}/installer/{installerID}`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)))
                .replace(`{${"installerID"}}`, encodeURIComponent(String(installerID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an estimate.
         * @param {string} estimateID 
         * @param {UpdateEstimate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimate: async (estimateID: string, body?: UpdateEstimate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateID' is not null or undefined
            assertParamExists('updateEstimate', 'estimateID', estimateID)
            const localVarPath = `/api/estimates/{estimateID}`
                .replace(`{${"estimateID"}}`, encodeURIComponent(String(estimateID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EstimatesApi - functional programming interface
 * @export
 */
export const EstimatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EstimatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign an estimator to an estimate.
         * @param {string} estimateID 
         * @param {AssignUsers} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignEstimator(estimateID: string, body?: AssignUsers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignEstimator(estimateID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign an installer to an estimate.
         * @param {string} estimateID 
         * @param {AssignUsers} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignInstaller(estimateID: string, body?: AssignUsers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignInstaller(estimateID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create estimate record.
         * @param {CreateEstimate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEstimate(body?: CreateEstimate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEstimate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a comment to an estimate
         * @param {string} estimateID 
         * @param {CreateEstimateComment} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEstimateComment(estimateID: string, body?: CreateEstimateComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEstimateComment(estimateID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an estimate.
         * @param {string} estimateID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEstimate(estimateID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEstimate(estimateID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get estimate record.
         * @param {string} estimateID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimate(estimateID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Estimate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimate(estimateID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query estimate comments.
         * @param {string} estimateID 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryEstimateComments(estimateID: string, page?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryEstimateComments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryEstimateComments(estimateID, page, pageSize, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query estimate records.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [city] 
         * @param {string} [province] 
         * @param {string} [country] 
         * @param {string} [postalCode] 
         * @param {string} [status] 
         * @param {string} [jobType] 
         * @param {string} [customer] 
         * @param {string} [estimator] 
         * @param {string} [installer] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryEstimates(page?: number, pageSize?: number, sort?: string, city?: string, province?: string, country?: string, postalCode?: string, status?: string, jobType?: string, customer?: string, estimator?: string, installer?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryEstimates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryEstimates(page, pageSize, sort, city, province, country, postalCode, status, jobType, customer, estimator, installer, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unassign an estimator from an estimate.
         * @param {string} estimateID 
         * @param {string} estimatorID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignEstimator(estimateID: string, estimatorID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignEstimator(estimateID, estimatorID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unassign an installer from an estimate.
         * @param {string} estimateID 
         * @param {string} installerID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignInstaller(estimateID: string, installerID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignInstaller(estimateID, installerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an estimate.
         * @param {string} estimateID 
         * @param {UpdateEstimate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstimate(estimateID: string, body?: UpdateEstimate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstimate(estimateID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EstimatesApi - factory interface
 * @export
 */
export const EstimatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EstimatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign an estimator to an estimate.
         * @param {EstimatesApiAssignEstimatorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignEstimator(requestParameters: EstimatesApiAssignEstimatorRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignEstimator(requestParameters.estimateID, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign an installer to an estimate.
         * @param {EstimatesApiAssignInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignInstaller(requestParameters: EstimatesApiAssignInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignInstaller(requestParameters.estimateID, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create estimate record.
         * @param {EstimatesApiCreateEstimateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimate(requestParameters: EstimatesApiCreateEstimateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createEstimate(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a comment to an estimate
         * @param {EstimatesApiCreateEstimateCommentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimateComment(requestParameters: EstimatesApiCreateEstimateCommentRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createEstimateComment(requestParameters.estimateID, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an estimate.
         * @param {EstimatesApiDeleteEstimateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstimate(requestParameters: EstimatesApiDeleteEstimateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteEstimate(requestParameters.estimateID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get estimate record.
         * @param {EstimatesApiGetEstimateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimate(requestParameters: EstimatesApiGetEstimateRequest, options?: AxiosRequestConfig): AxiosPromise<Estimate> {
            return localVarFp.getEstimate(requestParameters.estimateID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query estimate comments.
         * @param {EstimatesApiQueryEstimateCommentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEstimateComments(requestParameters: EstimatesApiQueryEstimateCommentsRequest, options?: AxiosRequestConfig): AxiosPromise<QueryEstimateComments200Response> {
            return localVarFp.queryEstimateComments(requestParameters.estimateID, requestParameters.page, requestParameters.pageSize, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query estimate records.
         * @param {EstimatesApiQueryEstimatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEstimates(requestParameters: EstimatesApiQueryEstimatesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryEstimates200Response> {
            return localVarFp.queryEstimates(requestParameters.page, requestParameters.pageSize, requestParameters.sort, requestParameters.city, requestParameters.province, requestParameters.country, requestParameters.postalCode, requestParameters.status, requestParameters.jobType, requestParameters.customer, requestParameters.estimator, requestParameters.installer, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign an estimator from an estimate.
         * @param {EstimatesApiUnassignEstimatorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignEstimator(requestParameters: EstimatesApiUnassignEstimatorRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.unassignEstimator(requestParameters.estimateID, requestParameters.estimatorID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign an installer from an estimate.
         * @param {EstimatesApiUnassignInstallerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignInstaller(requestParameters: EstimatesApiUnassignInstallerRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.unassignInstaller(requestParameters.estimateID, requestParameters.installerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an estimate.
         * @param {EstimatesApiUpdateEstimateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimate(requestParameters: EstimatesApiUpdateEstimateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateEstimate(requestParameters.estimateID, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignEstimator operation in EstimatesApi.
 * @export
 * @interface EstimatesApiAssignEstimatorRequest
 */
export interface EstimatesApiAssignEstimatorRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiAssignEstimator
     */
    readonly estimateID: string

    /**
     * 
     * @type {AssignUsers}
     * @memberof EstimatesApiAssignEstimator
     */
    readonly body?: AssignUsers
}

/**
 * Request parameters for assignInstaller operation in EstimatesApi.
 * @export
 * @interface EstimatesApiAssignInstallerRequest
 */
export interface EstimatesApiAssignInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiAssignInstaller
     */
    readonly estimateID: string

    /**
     * 
     * @type {AssignUsers}
     * @memberof EstimatesApiAssignInstaller
     */
    readonly body?: AssignUsers
}

/**
 * Request parameters for createEstimate operation in EstimatesApi.
 * @export
 * @interface EstimatesApiCreateEstimateRequest
 */
export interface EstimatesApiCreateEstimateRequest {
    /**
     * 
     * @type {CreateEstimate}
     * @memberof EstimatesApiCreateEstimate
     */
    readonly body?: CreateEstimate
}

/**
 * Request parameters for createEstimateComment operation in EstimatesApi.
 * @export
 * @interface EstimatesApiCreateEstimateCommentRequest
 */
export interface EstimatesApiCreateEstimateCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiCreateEstimateComment
     */
    readonly estimateID: string

    /**
     * 
     * @type {CreateEstimateComment}
     * @memberof EstimatesApiCreateEstimateComment
     */
    readonly body?: CreateEstimateComment
}

/**
 * Request parameters for deleteEstimate operation in EstimatesApi.
 * @export
 * @interface EstimatesApiDeleteEstimateRequest
 */
export interface EstimatesApiDeleteEstimateRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiDeleteEstimate
     */
    readonly estimateID: string
}

/**
 * Request parameters for getEstimate operation in EstimatesApi.
 * @export
 * @interface EstimatesApiGetEstimateRequest
 */
export interface EstimatesApiGetEstimateRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiGetEstimate
     */
    readonly estimateID: string
}

/**
 * Request parameters for queryEstimateComments operation in EstimatesApi.
 * @export
 * @interface EstimatesApiQueryEstimateCommentsRequest
 */
export interface EstimatesApiQueryEstimateCommentsRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimateComments
     */
    readonly estimateID: string

    /**
     * 
     * @type {number}
     * @memberof EstimatesApiQueryEstimateComments
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EstimatesApiQueryEstimateComments
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimateComments
     */
    readonly sort?: string
}

/**
 * Request parameters for queryEstimates operation in EstimatesApi.
 * @export
 * @interface EstimatesApiQueryEstimatesRequest
 */
export interface EstimatesApiQueryEstimatesRequest {
    /**
     * 
     * @type {number}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly city?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly province?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly country?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly postalCode?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly jobType?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly customer?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly estimator?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly installer?: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiQueryEstimates
     */
    readonly search?: string
}

/**
 * Request parameters for unassignEstimator operation in EstimatesApi.
 * @export
 * @interface EstimatesApiUnassignEstimatorRequest
 */
export interface EstimatesApiUnassignEstimatorRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiUnassignEstimator
     */
    readonly estimateID: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiUnassignEstimator
     */
    readonly estimatorID: string
}

/**
 * Request parameters for unassignInstaller operation in EstimatesApi.
 * @export
 * @interface EstimatesApiUnassignInstallerRequest
 */
export interface EstimatesApiUnassignInstallerRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiUnassignInstaller
     */
    readonly estimateID: string

    /**
     * 
     * @type {string}
     * @memberof EstimatesApiUnassignInstaller
     */
    readonly installerID: string
}

/**
 * Request parameters for updateEstimate operation in EstimatesApi.
 * @export
 * @interface EstimatesApiUpdateEstimateRequest
 */
export interface EstimatesApiUpdateEstimateRequest {
    /**
     * 
     * @type {string}
     * @memberof EstimatesApiUpdateEstimate
     */
    readonly estimateID: string

    /**
     * 
     * @type {UpdateEstimate}
     * @memberof EstimatesApiUpdateEstimate
     */
    readonly body?: UpdateEstimate
}

/**
 * EstimatesApi - object-oriented interface
 * @export
 * @class EstimatesApi
 * @extends {BaseAPI}
 */
export class EstimatesApi extends BaseAPI {
    /**
     * 
     * @summary Assign an estimator to an estimate.
     * @param {EstimatesApiAssignEstimatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public assignEstimator(requestParameters: EstimatesApiAssignEstimatorRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).assignEstimator(requestParameters.estimateID, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign an installer to an estimate.
     * @param {EstimatesApiAssignInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public assignInstaller(requestParameters: EstimatesApiAssignInstallerRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).assignInstaller(requestParameters.estimateID, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create estimate record.
     * @param {EstimatesApiCreateEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public createEstimate(requestParameters: EstimatesApiCreateEstimateRequest = {}, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).createEstimate(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a comment to an estimate
     * @param {EstimatesApiCreateEstimateCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public createEstimateComment(requestParameters: EstimatesApiCreateEstimateCommentRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).createEstimateComment(requestParameters.estimateID, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an estimate.
     * @param {EstimatesApiDeleteEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public deleteEstimate(requestParameters: EstimatesApiDeleteEstimateRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).deleteEstimate(requestParameters.estimateID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get estimate record.
     * @param {EstimatesApiGetEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public getEstimate(requestParameters: EstimatesApiGetEstimateRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).getEstimate(requestParameters.estimateID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query estimate comments.
     * @param {EstimatesApiQueryEstimateCommentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public queryEstimateComments(requestParameters: EstimatesApiQueryEstimateCommentsRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).queryEstimateComments(requestParameters.estimateID, requestParameters.page, requestParameters.pageSize, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query estimate records.
     * @param {EstimatesApiQueryEstimatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public queryEstimates(requestParameters: EstimatesApiQueryEstimatesRequest = {}, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).queryEstimates(requestParameters.page, requestParameters.pageSize, requestParameters.sort, requestParameters.city, requestParameters.province, requestParameters.country, requestParameters.postalCode, requestParameters.status, requestParameters.jobType, requestParameters.customer, requestParameters.estimator, requestParameters.installer, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassign an estimator from an estimate.
     * @param {EstimatesApiUnassignEstimatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public unassignEstimator(requestParameters: EstimatesApiUnassignEstimatorRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).unassignEstimator(requestParameters.estimateID, requestParameters.estimatorID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassign an installer from an estimate.
     * @param {EstimatesApiUnassignInstallerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public unassignInstaller(requestParameters: EstimatesApiUnassignInstallerRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).unassignInstaller(requestParameters.estimateID, requestParameters.installerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an estimate.
     * @param {EstimatesApiUpdateEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimatesApi
     */
    public updateEstimate(requestParameters: EstimatesApiUpdateEstimateRequest, options?: AxiosRequestConfig) {
        return EstimatesApiFp(this.configuration).updateEstimate(requestParameters.estimateID, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get server status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthcheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get server status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthcheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHealthcheck200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthcheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * 
         * @summary Get server status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthcheck(options?: AxiosRequestConfig): AxiosPromise<GetHealthcheck200Response> {
            return localVarFp.getHealthcheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * 
     * @summary Get server status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public getHealthcheck(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).getHealthcheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new user record.
         * @param {CreateUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (body?: CreateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user record.
         * @param {string} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('deleteUser', 'userID', userID)
            const localVarPath = `/api/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a user record.
         * @param {string} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('getUser', 'userID', userID)
            const localVarPath = `/api/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query user records.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUsers: async (page?: number, pageSize?: number, sort?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user record.
         * @param {string} userID 
         * @param {UpdateUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userID: string, body?: UpdateUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('updateUser', 'userID', userID)
            const localVarPath = `/api/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new user record.
         * @param {CreateUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(body?: CreateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a user record.
         * @param {string} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a user record.
         * @param {string} userID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query user records.
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [sort] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryUsers(page?: number, pageSize?: number, sort?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryUsers(page, pageSize, sort, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user record.
         * @param {string} userID 
         * @param {UpdateUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userID: string, body?: UpdateUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new user record.
         * @param {UsersApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: UsersApiCreateUserRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createUser(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user record.
         * @param {UsersApiDeleteUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(requestParameters: UsersApiDeleteUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUser(requestParameters.userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a user record.
         * @param {UsersApiGetUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(requestParameters: UsersApiGetUserRequest, options?: AxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getUser(requestParameters.userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query user records.
         * @param {UsersApiQueryUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUsers(requestParameters: UsersApiQueryUsersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<QueryUsers200Response> {
            return localVarFp.queryUsers(requestParameters.page, requestParameters.pageSize, requestParameters.sort, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user record.
         * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UsersApiUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUser(requestParameters.userID, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUser operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserRequest
 */
export interface UsersApiCreateUserRequest {
    /**
     * 
     * @type {CreateUser}
     * @memberof UsersApiCreateUser
     */
    readonly body?: CreateUser
}

/**
 * Request parameters for deleteUser operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserRequest
 */
export interface UsersApiDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUser
     */
    readonly userID: string
}

/**
 * Request parameters for getUser operation in UsersApi.
 * @export
 * @interface UsersApiGetUserRequest
 */
export interface UsersApiGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUser
     */
    readonly userID: string
}

/**
 * Request parameters for queryUsers operation in UsersApi.
 * @export
 * @interface UsersApiQueryUsersRequest
 */
export interface UsersApiQueryUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiQueryUsers
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiQueryUsers
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiQueryUsers
     */
    readonly sort?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiQueryUsers
     */
    readonly search?: string
}

/**
 * Request parameters for updateUser operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserRequest
 */
export interface UsersApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUpdateUser
     */
    readonly userID: string

    /**
     * 
     * @type {UpdateUser}
     * @memberof UsersApiUpdateUser
     */
    readonly body?: UpdateUser
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Create a new user record.
     * @param {UsersApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(requestParameters: UsersApiCreateUserRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user record.
     * @param {UsersApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(requestParameters: UsersApiDeleteUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(requestParameters.userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a user record.
     * @param {UsersApiGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(requestParameters: UsersApiGetUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(requestParameters.userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query user records.
     * @param {UsersApiQueryUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public queryUsers(requestParameters: UsersApiQueryUsersRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).queryUsers(requestParameters.page, requestParameters.pageSize, requestParameters.sort, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user record.
     * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(requestParameters: UsersApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(requestParameters.userID, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


