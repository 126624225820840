<template>
  <div class="menubar flex flex-row justify-content-start align-items-center flex-nowrap">
    <Button icon="pi pi-bars" class="p-button-rounded p-button-text mr-2" @click="openSidebar()"></Button>
    <div v-if="currentUser" class="header-text font-medium text-2xl text-800">Welcome, {{ currentUser.firstName }}!</div>

    <!-- <Dropdown v-model="selectedTheme" :options="themeOptions" option-label="name" option-value="url"></Dropdown> -->
    <SideBarMobile v-model:visible="showSidebar"></SideBarMobile>
  </div>
</template>

<script setup lang="ts">
import { watch, ref } from "vue";
import { useHead } from "@vueuse/head";
import { useCurrentUser } from "@/composables/auth";

import SideBarMobile from "./SideBarMobile.vue";

import Button from "primevue/button";

const currentUser = useCurrentUser();

const showSidebar = ref(false);

function openSidebar() {
  showSidebar.value = true;
}

const selectedTheme = ref("https://unpkg.com/primevue/resources/themes/soho-dark/theme.css");
const themeOptions = [
  {
    name: "Saga Blue",
    url: "https://unpkg.com/primevue/resources/themes/saga-blue/theme.css",
  },
  {
    name: "Vela Blue",
    url: "https://unpkg.com/primevue/resources/themes/vela-blue/theme.css",
  },
  {
    name: "Bootstrap Light Blue",
    url: "https://unpkg.com/primevue/resources/themes/bootstrap4-light-blue/theme.css",
  },
  {
    name: "Bootstrap Dark Blue",
    url: "https://unpkg.com/primevue/resources/themes/bootstrap4-dark-blue/theme.css",
  },
  {
    name: "Lara Light Blue",
    url: "https://unpkg.com/primevue/resources/themes/lara-light-blue/theme.css",
  },
  {
    name: "Lara Dark Blue",
    url: "https://unpkg.com/primevue/resources/themes/lara-dark-blue/theme.css",
  },
  {
    name: "Soho Light",
    url: "https://unpkg.com/primevue/resources/themes/soho-light/theme.css",
  },
  {
    name: "Soho Dark",
    url: "https://unpkg.com/primevue/resources/themes/soho-dark/theme.css",
  },
  {
    name: "Arya Blue",
    url: "https://unpkg.com/primevue/resources/themes/arya-blue/theme.css",
  },
  {
    name: "Nova",
    url: "https://unpkg.com/primevue/resources/themes/luna-blue/theme.css",
  },
  {
    name: "Luna Blue",
    url: "https://unpkg.com/primevue/resources/themes/luna-blue/theme.css",
  },
  {
    name: "Rhea",
    url: "https://unpkg.com/primevue/resources/themes/rhea/theme.css",
  },
];

watch(selectedTheme, (value) => {
  useHead({
    link: [
      {
        rel: "stylesheet",
        href: value,
        id: "theme-file",
      },
    ],
  });
});
</script>

<style lang="scss" scoped>
@use "../assets/colours";

.menubar {
  width: 100%;
  height: 67px;
  border-bottom: 1px var(--surface-border) solid;
  padding-inline: 16px;
}
</style>
