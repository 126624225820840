import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyCST4_v0BGCeO1B8OLCXKfnInvDOs9K4YI",
  authDomain: "estimation-station-dev.firebaseapp.com",
  projectId: "estimation-station-dev",
  storageBucket: "estimation-station-dev.appspot.com",
  messagingSenderId: "692367099492",
  appId: "1:692367099492:web:6b5745328953698e35c171",
  measurementId: "G-8EY8L8MDWH",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

const firebaseAuth = getAuth(firebaseApp);
export { firebaseAuth };
