<template>
  <div class="flex justify-content-center align-items-center h-screen surface-50">
    <div v-if="currentUser === null || isError || isLoading" class="card">
      <div class="card-title text-center"></div>
      <div class="text-center text-4xl text-800 mb-3">Estimation Station</div>
      <div class="flex justify-content-center mb-4">
        <div class="divider"></div>
      </div>

      <div class="p-fluid p-field">
        <label class="font-light" for="email">Email</label>
        <InputText id="email" v-model="email" :class="isError ? 'p-invalid' : ''" aria-describedby="emailHelp" />
        <small v-if="showEmailError" id="emailHelp">{{ emailErrorMsg }}</small>
      </div>
      <div class="p-fluid p-field mt-2">
        <label class="font-light" for="password">Password</label>
        <Password id="password" v-model="password" :feedback="false" toggleMask :class="isError ? 'p-invalid' : ''" aria-describedby="passwordHelp" />
        <small v-if="showPasswordError" id="passwordHelp">{{ passwordErrorMsg }}</small>
      </div>

      <div class="button-container">
        <Button v-if="!isLoading" class="w-full p-button-raised" label="Login" @click="login" />
        <Button v-else class="w-full p-button-raised" icon="pi pi-spin pi-spinner" disabled />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getCurrentUser, loginWithEmailAndPassword, useCurrentUser } from "@/composables/auth";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";

const router = useRouter();
const route = useRoute();
const currentUser = useCurrentUser();

const email = ref("");
const password = ref("");
const lastEmail = ref("");
const lastPassword = ref("");

const emailErrorMsg = ref("");
const passwordErrorMsg = ref("");

const isError = ref(false);
const isLoading = ref(false);

const showEmailError = computed(() => {
  return emailErrorMsg.value && email.value === lastEmail.value;
});

const showPasswordError = computed(() => {
  return passwordErrorMsg.value && password.value === lastPassword.value;
});

onMounted(async () => {
  const currentUser = await getCurrentUser();
  if (currentUser) {
    redirectAfterLogin();
  }
});

function login(): void {
  isError.value = false;
  lastEmail.value = email.value;
  lastPassword.value = password.value;

  if (email.value === "") {
    emailErrorMsg.value = "Please enter an email address";
    isError.value = true;
  }
  if (password.value === "") {
    passwordErrorMsg.value = "Password cannot be empty";
    isError.value = true;
  }
  if (isError.value) {
    return;
  }

  isLoading.value = true;
  emailErrorMsg.value = "";
  passwordErrorMsg.value = "";
  loginWithEmailAndPassword(email.value, password.value)
    .then(() => {
      isError.value = false;
      redirectAfterLogin();
    })
    .catch(() => {
      passwordErrorMsg.value = "Wrong email or password";
      isError.value = true;
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function redirectAfterLogin() {
  const to = route.query.redirect && typeof route.query.redirect === "string" ? route.query.redirect : "/";
  router.push(to);
}
</script>

<style lang="scss" scoped>
@use "../assets/colours";
.card {
  width: 100%;
  max-width: 420px;
  padding: 2rem;
}

.button-container {
  margin-top: 2rem;
}

@media (max-width: 500px) {
  .card {
    max-width: none;
    box-shadow: none;
    background-color: var(--surface-50);
  }
}

small {
  color: var(--red-600);
}

.divider {
  width: 82%;
  border-top: 1px var(--surface-border) solid;
}
</style>
