import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "@/composables/auth";

import LoginView from "@/views/LoginView.vue";
import DashboardView from "../views/DashboardView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
    // if (savedPosition) {
    //   return savedPosition;
    // } else {
    //   return { top: 0 };
    // }
  },
  routes: [
    {
      path: "/",
      name: "Landing",
      redirect: "/dashboard",
    },

    {
      path: "/login",
      name: "Login",
      component: LoginView,
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: DashboardView,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "DashboardLanding",
          redirect: (to) => {
            return to.path + "/jobs";
          },
        },

        {
          path: "jobs/:id",
          name: "JobDetails",
          // This generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          component: () => import("../views/dashboard/EstimateDetails.vue"),
        },

        {
          path: "jobs/:id/inspection",
          name: "DoInspection",
          component: () => import("../views/dashboard/estimate-inspection/EstimateInspection.vue"),
          children: [
            {
              path: "photos",
              name: "InspectionPhotos",
              component: () => import("../views/dashboard/estimate-inspection/EstimateInspectionPhotos.vue"),
            },
            {
              path: "property",
              name: "InspectionProperty",
              component: () => import("../views/dashboard/estimate-inspection/EstimateInspectionProperty.vue"),
            },
            {
              path: "materials",
              name: "InspectionMaterials",
              component: () => import("../views/dashboard/estimate-inspection/EstimateInspectionMaterials.vue"),
            },
            {
              path: "confirm",
              name: "InspectionConfirm",
              component: () => import("../views/dashboard/estimate-inspection/EstimateInspectionConfirm.vue"),
            },
          ],
        },

        {
          path: "jobs",
          name: "Jobs",
          component: () => import("../views/dashboard/EstimatesView.vue"),
        },

        {
          path: "users",
          name: "Users",
          component: () => import("../views/dashboard/UsersView.vue"),
        },
      ],
    },
    {
      path: "/dashboard/jobs/:id/comments",
      name: "JobComments",
      component: () => import("../views/dashboard/JobComments.vue"),
    },
    // Catch all
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ],
});

router.beforeEach(async (to) => {
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      return {
        path: "/login",
        query: {
          // Keep the current path in the query so we can redirect to it after login
          redirect: to.fullPath,
        },
      };
    }
  }
});

export default router;
