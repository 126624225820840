import { ref } from "vue";

import { firebaseAuth } from "@/lib/firebase";
import { signInWithEmailAndPassword, signOut, type User as FirebaseUser } from "firebase/auth";

import { type User, UsersApi } from "@/lib/api";

const auth = firebaseAuth;
const currentFirebaseUser = ref<FirebaseUser | null | undefined>(undefined); // undefined before initial load, null if not signed in
const currentUser = ref<User | null | undefined>(undefined); // undefined before initial load, null if not signed in
const usersApi = new UsersApi(undefined, "");

auth.onAuthStateChanged(async (newUser) => {
  currentFirebaseUser.value = newUser;
  if (newUser) {
    const token = await newUser.getIdToken();

    usersApi
      .getUser(
        { userID: newUser.uid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((userDetails) => {
        currentUser.value = userDetails.data;
      })
      .catch((err) => {
        console.error(err);
        currentUser.value = null;
      });
  } else {
    currentUser.value = newUser;
  }
});

function useCurrentUser() {
  return currentUser;
}

async function getCurrentUser(): Promise<User | null> {
  return new Promise((resolve) => {
    const loop = (): any => (currentUser.value !== undefined ? resolve(currentUser.value) : setTimeout(loop));
    loop();
  });
}

async function getAuthToken() {
  if (!currentFirebaseUser.value) {
    return "";
  }

  return currentFirebaseUser.value.getIdToken();
}

async function loginWithEmailAndPassword(email: string, password: string): Promise<User | null> {
  currentUser.value = undefined;
  await signInWithEmailAndPassword(auth, email, password);

  return new Promise((resolve) => {
    const loop = (): any => (currentUser.value !== undefined ? resolve(currentUser.value) : setTimeout(loop));
    loop();
  });
}

async function logout() {
  return signOut(auth);
}

export { getCurrentUser, getAuthToken, useCurrentUser, loginWithEmailAndPassword, logout };
