<template>
  <Sidebar v-model:visible="isVisible" :baseZIndex="1000">
    <div class="sidebar flex flex-column flex-nowrap h-full">
      <div class="header flex justify-content-center align-items-center">
        <div class="header-text text-2xl text-primary">Estimation Station</div>
        <div class="header-text-short font-light text-2xl text-800">ES</div>
      </div>
      <div class="flex justify-content-center">
        <div class="divider"></div>
      </div>
      <div class="menu h-full flex flex-column align-items-center justify-content-between mb-3">
        <PMenu :model="menuOptions"></PMenu>
        <Button class="logout-button p-button-text p-button-danger" icon="pi pi-sign-out" label="Logout" @click="onLogout" />
      </div>
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";

import { logout } from "@/composables/auth";

import Sidebar from "primevue/sidebar";
import PMenu from "primevue/menu";
import Button from "primevue/button";

const props = defineProps({
  visible: Boolean,
});
const emit = defineEmits(["update:visible"]);

const router = useRouter();

const { visible } = toRefs(props);
const isVisible = ref(false);

const menuOptions = [
  {
    label: "Jobs",
    icon: "pi pi-inbox",
    // to: "/dashboard/estimates",
    command: () => {
      router.push("/dashboard/jobs");
      closeModal();
    },
  },
  {
    label: "Users",
    icon: "pi pi-users",
    // to: "/dashboard/users",
    command: () => {
      router.push("/dashboard/users");
      closeModal();
    },
  },
];

watch(visible, (value) => {
  isVisible.value = value;
});

watch(isVisible, (value) => {
  emit("update:visible", value);
});

function onLogout() {
  logout().then(() => {
    router.push("/login");
  });
}

function closeModal() {
  isVisible.value = false;
}
</script>

<style lang="scss" scoped>
@use "../assets/colours";

.sidebar {
  // width: 220px;
  // border-right: 1px var(--surface-border) solid;
  overflow: hidden;

  .header {
    height: 75px;
    // max-height: 66px;
    line-height: 1;

    .header-text {
      height: fit-content;
    }

    .header-text-short {
      display: none;
    }
  }
}

.divider {
  width: 82%;
  border-top: 1px var(--surface-border) solid;
}

.menu {
  margin-top: 30px;
  :deep(.p-menu) {
    border: none;
    width: 100%;
    padding: 0;
    overflow: hidden;

    .p-menuitem:has(.router-link-active) {
      // &.p-focus > .p-menuitem-content {
      //   background-color: var(--primary-color) !important;
      // }
      .p-menuitem-content:has(.router-link-active) {
        border-left: 4px solid var(--primary-color);
        // background-color: var(--primary-color);
        &:hover {
          //   background-color: var(--primary-color) !important;
          background: var(--surface-0) !important;
        }
      }
    }

    .router-link-active-exact {
      .p-menuitem-text {
        color: var(--text-color) !important;
      }
      .p-menuitem-icon {
        color: var(--text-color) !important;
        margin-left: -4px;
      }
    }
  }
}
</style>
